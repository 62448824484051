import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { MdOutlineAdd } from 'react-icons/md';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: 4
  }
}));

function SetCommissionOnBoarding() {
  const [loanInterested, setLoanInterested] = useState(' ');
  const [bankName, setBankName] = useState('Select Bank');
  const [loanType, setLoanType] = useState('Select Loan Type');

  return (
    <Page title="Partner Management System || On Boarding">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Set Commission" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Box className="">
                <Grid container spacing={3}>
                  <Grid item xs={3}>
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        Bank
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={bankName}
                        onChange={(e) => {
                          setBankName(e.target.value);
                        }}
                      >
                        <MenuItem value="Select Bank" disabled>
                          Select Bank
                        </MenuItem>
                        <MenuItem value="HDFC">HDFC</MenuItem>
                        <MenuItem value="ICICI">ICICI</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        Loan Type
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={loanType}
                        onChange={(e) => {
                          setLoanType(e.target.value);
                        }}
                      >
                        <MenuItem value="Select Loan Type" disabled>
                          Select Bank
                        </MenuItem>
                        <MenuItem value="Home Loan">Home Loan</MenuItem>
                        <MenuItem value="Education Loan">Education Loan</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        Our Commission
                      </Typography>
                      <TextField
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        placeholder="Type Our Commission %"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        Partner Share
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type Partner Share %"
                          sx={{ marginRight: '1rem' }}
                        />
                        <Tooltip title="Add" placement="top" arrow>
                          <IconButton className="squareIconButton">
                            <MdOutlineAdd size={16} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box className="" mb={3}>
                <Button variant="contained" className="mainBtn">
                  Submit
                </Button>
              </Box>
              <TableContainer
                sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
                aria-label="simple table"
              >
                <Table border={1} borderColor="#ebedf2">
                  <TableHead>
                    <TableRow>
                      <TableCell>Bank</TableCell>
                      <TableCell align="left">Loan Type</TableCell>
                      <TableCell align="left">Our Commission</TableCell>
                      <TableCell align="left">Partner Share</TableCell>
                      <TableCell align="left">Modified on</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">HDFC</TableCell>
                      <TableCell align="left">Education Loan</TableCell>
                      <TableCell align="left">5%</TableCell>
                      <TableCell align="left">2%</TableCell>
                      <TableCell align="left">27 April, 2022</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default SetCommissionOnBoarding;
