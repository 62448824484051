import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  Paper,
  Checkbox
} from '@mui/material';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import upload from '@iconify/icons-ic/file-upload';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';
import svgAvtar from './banks-card.png';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function AddEditOnBoarding() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [partnerType, setPartnerType] = useState('Individual');
  const [partnerRole, setPartnerRole] = useState('Leads');
  const [curFileName, setCurFileName] = useState('');

  const [aadharPreview, setAadharPreview] = useState(svgAvtar);
  const [panPreview, setPanPreview] = useState(svgAvtar);
  const [checkPreview, setCheckPreview] = useState(svgAvtar);
  const [gstPreview, setGstPreview] = useState(svgAvtar);
  // const [aadharPreview, setAadharPreview] = useState('');

  // File upload functions
  const handleFileUpload = async (e, setPreview, files) => {
    let file = '';
    if (files) {
      file = files;
    } else {
      file = e.target.files[0];
    }
    setCurFileName(file.name);
    setPreview(URL.createObjectURL(file));
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e, setPreview) => {
    e.preventDefault();
    const { files } = e.dataTransfer.files;
    // console.log(files[0]);
    handleFileUpload(e, setPreview, files[0]);
  };

  const uploadFileClick = (e, btnId) => {
    const browseField = document.getElementById(btnId);
    browseField.click();
  };
  return (
    <Page title="Partner Management System || On Boarding">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info={rowId ? 'Edit Partner' : 'Add New Partner'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Box className="fixedBox">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid item xs={6}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Partner Type
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={partnerType}
                          onChange={(e) => {
                            setPartnerType(e.target.value);
                            setPanPreview(svgAvtar);
                            setCheckPreview(svgAvtar);
                          }}
                        >
                          <MenuItem value="Individual">Individual</MenuItem>
                          <MenuItem value="Company">Company</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                  {partnerType === 'Individual' ? (
                    <>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Name
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type name..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Father Name
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type father name..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            DOB
                          </Typography>
                          <TextField
                            type="date"
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Select DOB..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Address
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type address..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Mobile
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type mobile number..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="formRowHolder fullrow">
                          <Typography>
                            <strong>Uploads</strong>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Aadhar
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            border={1}
                            borderColor="#ddd"
                            p={1}
                          >
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              className="fileInput"
                              id="contained-button-file"
                              multiple
                              type="file"
                              onChange={(e) => handleFileUpload(e, setAadharPreview)}
                            />
                            <label htmlFor="contained-button-file">
                              <Box
                                variant="contained"
                                color="primary"
                                component="span"
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={(e) => fileDrop(e, setAadharPreview)}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Icon icon={upload} />
                                {/* <FaUpload className="globalFileUploadIcon" style={{ fontSize: '16px' }} /> */}
                                <Typography
                                  className=""
                                  style={{
                                    fontSize: 12,
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                  }}
                                >
                                  Drag and drop File or
                                </Typography>
                                <img src={aadharPreview} alt="aadhar" width="100%" height="220" />
                                <Typography className="globalDragDropButton">
                                  <Button
                                    onClick={(e) => uploadFileClick(e, 'contained-button-file')}
                                    variant="contained"
                                    className="UploadBtn globalUploadButton"
                                  >
                                    Browser
                                  </Button>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="globalDragDropSubFileHeading"
                                  style={{ fontSize: 12, marginTop: '10px' }}
                                >
                                  Maximum upload file size: 1GB
                                </Typography>
                                {curFileName}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Pan
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            border={1}
                            borderColor="#ddd"
                            p={1}
                          >
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              className="fileInput"
                              id="contained-button-file2"
                              multiple
                              type="file"
                              onChange={(e) => handleFileUpload(e, setPanPreview)}
                            />
                            <label htmlFor="contained-button-file2">
                              <Box
                                variant="contained"
                                color="primary"
                                component="span"
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={(e) => fileDrop(e, setPanPreview)}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Icon icon={upload} />
                                {/* <FaUpload className="globalFileUploadIcon" style={{ fontSize: '16px' }} /> */}
                                <Typography
                                  className=""
                                  style={{
                                    fontSize: 12,
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                  }}
                                >
                                  Drag and drop File or
                                </Typography>
                                <img src={panPreview} alt="PAN" width="100%" height="220" />
                                <Typography className="globalDragDropButton">
                                  <Button
                                    onClick={(e) => uploadFileClick(e, 'contained-button-file2')}
                                    variant="contained"
                                    className="UploadBtn globalUploadButton"
                                  >
                                    Browser
                                  </Button>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="globalDragDropSubFileHeading"
                                  style={{ fontSize: 12, marginTop: '10px' }}
                                >
                                  Maximum upload file size: 1GB
                                </Typography>
                                {curFileName}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Check book
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            border={1}
                            borderColor="#ddd"
                            p={1}
                          >
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              className="fileInput"
                              id="contained-button-file3"
                              multiple
                              type="file"
                              onChange={(e) => handleFileUpload(e, setCheckPreview)}
                            />
                            <label htmlFor="contained-button-file3">
                              <Box
                                variant="contained"
                                color="primary"
                                component="span"
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={(e) => fileDrop(e, setCheckPreview)}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Icon icon={upload} />
                                {/* <FaUpload className="globalFileUploadIcon" style={{ fontSize: '16px' }} /> */}
                                <Typography
                                  className=""
                                  style={{
                                    fontSize: 12,
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                  }}
                                >
                                  Drag and drop File or
                                </Typography>
                                <img
                                  src={checkPreview}
                                  alt="check book"
                                  width="100%"
                                  height="220"
                                />
                                <Typography className="globalDragDropButton">
                                  <Button
                                    onClick={(e) => uploadFileClick(e, 'contained-button-file3')}
                                    variant="contained"
                                    className="UploadBtn globalUploadButton"
                                  >
                                    Browser
                                  </Button>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="globalDragDropSubFileHeading"
                                  style={{ fontSize: 12, marginTop: '10px' }}
                                >
                                  Maximum upload file size: 1GB
                                </Typography>
                                {curFileName}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Company Name
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type name..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Address
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type address..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Contact Person Name
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type contact person name..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Contact Number
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            placeholder="Type mobile number..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            GST Number
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            type="number"
                            placeholder="Type mobile number..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            PAN Number
                          </Typography>
                          <TextField
                            fullWidth
                            inputProps={{
                              className: 'textInput'
                            }}
                            type="number"
                            placeholder="Type mobile number..."
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box className="formRowHolder fullrow">
                          <Typography>
                            <strong>Uploads</strong>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            GST
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            border={1}
                            borderColor="#ddd"
                            p={1}
                          >
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              className="fileInput"
                              id="contained-button-file4"
                              multiple
                              type="file"
                              onChange={(e) => handleFileUpload(e, setAadharPreview)}
                            />
                            <label htmlFor="contained-button-file4">
                              <Box
                                variant="contained"
                                color="primary"
                                component="span"
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={(e) => fileDrop(e, setGstPreview)}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Icon icon={upload} />
                                {/* <FaUpload className="globalFileUploadIcon" style={{ fontSize: '16px' }} /> */}
                                <Typography
                                  className=""
                                  style={{
                                    fontSize: 12,
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                  }}
                                >
                                  Drag and drop File or
                                </Typography>
                                <img src={gstPreview} alt="aadhar" width="100%" height="220" />
                                <Typography className="globalDragDropButton">
                                  <Button
                                    onClick={(e) => uploadFileClick(e, 'contained-button-file4')}
                                    variant="contained"
                                    className="UploadBtn globalUploadButton"
                                  >
                                    Browser
                                  </Button>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="globalDragDropSubFileHeading"
                                  style={{ fontSize: 12, marginTop: '10px' }}
                                >
                                  Maximum upload file size: 1GB
                                </Typography>
                                {curFileName}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Pan
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            border={1}
                            borderColor="#ddd"
                            p={1}
                          >
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              className="fileInput"
                              id="contained-button-file2"
                              multiple
                              type="file"
                              onChange={(e) => handleFileUpload(e, setPanPreview)}
                            />
                            <label htmlFor="contained-button-file2">
                              <Box
                                variant="contained"
                                color="primary"
                                component="span"
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={(e) => fileDrop(e, setPanPreview)}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Icon icon={upload} />
                                {/* <FaUpload className="globalFileUploadIcon" style={{ fontSize: '16px' }} /> */}
                                <Typography
                                  className=""
                                  style={{
                                    fontSize: 12,
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                  }}
                                >
                                  Drag and drop File or
                                </Typography>
                                <img src={panPreview} alt="PAN" width="100%" height="220" />
                                <Typography className="globalDragDropButton">
                                  <Button
                                    onClick={(e) => uploadFileClick(e, 'contained-button-file2')}
                                    variant="contained"
                                    className="UploadBtn globalUploadButton"
                                  >
                                    Browser
                                  </Button>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="globalDragDropSubFileHeading"
                                  style={{ fontSize: 12, marginTop: '10px' }}
                                >
                                  Maximum upload file size: 1GB
                                </Typography>
                                {curFileName}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box className="formRowHolder fullrow">
                          <Typography variant="h6" className="formLabel">
                            Check book
                          </Typography>
                          <Box
                            display="flex"
                            flexDirection="column"
                            border={1}
                            borderColor="#ddd"
                            p={1}
                          >
                            <input
                              accept="image/png, image/gif, image/jpeg"
                              className="fileInput"
                              id="contained-button-file3"
                              multiple
                              type="file"
                              onChange={(e) => handleFileUpload(e, setCheckPreview)}
                            />
                            <label htmlFor="contained-button-file3">
                              <Box
                                variant="contained"
                                color="primary"
                                component="span"
                                onDragOver={dragOver}
                                onDragEnter={dragEnter}
                                onDragLeave={dragLeave}
                                onDrop={(e) => fileDrop(e, setCheckPreview)}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                              >
                                <Icon icon={upload} />
                                {/* <FaUpload className="globalFileUploadIcon" style={{ fontSize: '16px' }} /> */}
                                <Typography
                                  className=""
                                  style={{
                                    fontSize: 12,
                                    marginBottom: '10px',
                                    marginTop: '10px'
                                  }}
                                >
                                  Drag and drop File or
                                </Typography>
                                <img
                                  src={checkPreview}
                                  alt="check book"
                                  width="100%"
                                  height="220"
                                />
                                <Typography className="globalDragDropButton">
                                  <Button
                                    onClick={(e) => uploadFileClick(e, 'contained-button-file3')}
                                    variant="contained"
                                    className="UploadBtn globalUploadButton"
                                  >
                                    Browser
                                  </Button>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className="globalDragDropSubFileHeading"
                                  style={{ fontSize: 12, marginTop: '10px' }}
                                >
                                  Maximum upload file size: 1GB
                                </Typography>
                                {curFileName}
                              </Box>
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Box className="formRowHolder">&nbsp;</Box>
                <Box className="formRowHolder">
                  <Button variant="contained">Save</Button>
                </Box>
              </Box>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditOnBoarding;
