import React, { useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card
} from '@mui/material';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams } from 'react-router-dom';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function EditAgreement() {
  const { rowId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };
  const handlePrev = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };
  return (
    <Page title="Partner Managemet System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={8}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Edit Agreement" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card sx={{ borderRadius: '0' }}>
            <Scrollbar>
              <Stepper
                connector=""
                activeStep={activeStep}
                sx={{ marginBottom: '25px' }}
                className="stepWizardOuter"
              >
                <Step className="stepWizard">
                  <StepLabel className="stepWizardLabel">Add Bank</StepLabel>
                </Step>

                <Step className="stepWizard">
                  <StepLabel className="stepWizardLabel">Add Branch</StepLabel>
                </Step>

                <Step className="stepWizard">
                  <StepLabel className="stepWizardLabel">Add Commission</StepLabel>
                </Step>

                <Step className="stepWizard">
                  <StepLabel className="stepWizardLabel">Uploaf Agreement</StepLabel>
                </Step>

                <Step className="stepWizard">
                  <StepLabel className="stepWizardLabel">Review</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 ? (
                <Step1Content handleNext={handleNext} />
              ) : activeStep === 1 ? (
                <Step2Content handleNext={handleNext} handlePrev={handlePrev} />
              ) : activeStep === 2 ? (
                <Step3Content handleNext={handleNext} handlePrev={handlePrev} />
              ) : activeStep === 3 ? (
                <Step4Content handleNext={handleNext} handlePrev={handlePrev} />
              ) : (
                <Step5Content handlePrev={handlePrev} />
              )}
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}

function Step1Content({ handleNext }) {
  const [selectCommissionStructure, setSelectCommissionStructure] = useState(
    'Select Commission Structure'
  );
  const [selectTurnoverBenefit, setSelectTurnoverBenefit] = useState('Select Turnover Benefit');
  return (
    <>
      <Box className="">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Bank Name
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Bank Name"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Bank Address
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Bank Address"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                PAN No
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type PAN No"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                GSTIN
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type GSTIN"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="mb-3">
              <Typography variant="h6" className="formLabel">
                Commission Structure
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value={selectCommissionStructure}
                onChange={(e) => setSelectCommissionStructure(e.target.value)}
              >
                <MenuItem value="Select Commission Structure" disabled>
                  Select Commission Structure
                </MenuItem>
                <MenuItem value="Branch">Branch</MenuItem>
                <MenuItem value="Bank">Bank</MenuItem>
              </Select>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="mb-3">
              <Typography variant="h6" className="formLabel">
                Turnover Benefit
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value={selectTurnoverBenefit}
                onChange={(e) => setSelectTurnoverBenefit(e.target.value)}
              >
                <MenuItem value="Select Turnover Benefit" disabled>
                  Select Turnover Benefit
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step2Content({ handleNext, handlePrev }) {
  const [selectBranch, setSelectBranch] = useState('Select Branch');
  return (
    <>
      <Box className="">
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Name
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Branch Name"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="">
              <Typography variant="h6" className="formLabel">
                Branch Address
              </Typography>
              <TextField
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                placeholder="Type Bank Address"
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="mb-3">
              <Typography variant="h6" className="formLabel">
                Contact Person
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type Contact Person"
                  sx={{ marginRight: '1rem' }}
                />
                <Tooltip title="Add" placement="top" arrow>
                  <IconButton className="squareIconButton">
                    <MdOutlineAdd size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <TableContainer
        sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
        aria-label="simple table"
      >
        <Table border={1} borderColor="#ebedf2">
          <TableHead>
            <TableRow>
              <TableCell>Branch Name</TableCell>
              <TableCell align="left">Branch Address</TableCell>
              <TableCell align="left">Contact Person</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">HDFC</TableCell>
              <TableCell align="left">Ramesh Vihar, New Delhi</TableCell>
              <TableCell align="left">Ramesh</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step3Content({ handleNext, handlePrev }) {
  const [selectLoan, setSelectLoan] = useState('Select Loan');
  const [selectBranch, setSelectBranch] = useState('Select Branch');
  const [showHide, setShowHide] = useState('Individual');
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(getUserType);
  };
  const [userType, setUserType] = useState('Individual');
  return (
    <>
      <Box className="">
        <Box className="mb-3">
          <Typography variant="h6" className="formLabel">
            Commision Structure
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Individual"
              name="radio-buttons-group"
              value={userType}
              onChange={(e) => {
                handleShowHide(e);
                setUserType(e.target.value);
              }}
            >
              <Box className="displayFlex alignItemCenter">
                <FormControlLabel
                  value="Individual"
                  control={<Radio size="small" />}
                  sx={{ marginRight: '1rem' }}
                  label="Individual"
                />
                <FormControlLabel
                  value="Volume"
                  control={<Radio size="small" />}
                  sx={{ marginRight: '1rem' }}
                  label="Volume"
                />
                <FormControlLabel
                  value="Both"
                  control={<Radio size="small" />}
                  sx={{ marginRight: '1rem' }}
                  label="Both"
                />
              </Box>
            </RadioGroup>
          </FormControl>
        </Box>
        {showHide === 'Individual' && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectLoan}
                    onChange={(e) => setSelectLoan(e.target.value)}
                  >
                    <MenuItem value="Select Loan" disabled>
                      Select Loan
                    </MenuItem>
                    <MenuItem value="Education">Education Loan</MenuItem>
                    <MenuItem value="Home">Home Loan</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectBranch}
                    onChange={(e) => setSelectBranch(e.target.value)}
                  >
                    <MenuItem value="Select Branch" disabled>
                      Select Branch
                    </MenuItem>
                    <MenuItem value="HDFC">HDFC</MenuItem>
                    <MenuItem value="ICICI">ICICI</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="mb-3">
                  <Typography variant="h6" className="formLabel">
                    Commission %
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Commission %"
                      sx={{ marginRight: '1rem' }}
                    />
                    <Tooltip title="Add" placement="top" arrow>
                      <IconButton className="squareIconButton">
                        <MdOutlineAdd size={16} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <TableContainer
              sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
              aria-label="simple table"
            >
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <TableCell>Loan Type</TableCell>
                    <TableCell align="left">Branch</TableCell>
                    <TableCell align="left">Commission %</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">Home loan</TableCell>
                    <TableCell align="left">HDFC Ramesh Vihar</TableCell>
                    <TableCell align="left">5%</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {showHide === 'Volume' && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectLoan}
                    onChange={(e) => setSelectLoan(e.target.value)}
                  >
                    <MenuItem value="Select Loan" disabled>
                      Select Loan
                    </MenuItem>
                    <MenuItem value="Education">Education Loan</MenuItem>
                    <MenuItem value="Home">Home Loan</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectBranch}
                    onChange={(e) => setSelectBranch(e.target.value)}
                  >
                    <MenuItem value="Select Branch" disabled>
                      Select Branch
                    </MenuItem>
                    <MenuItem value="HDFC">HDFC</MenuItem>
                    <MenuItem value="ICICI">ICICI</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Amount
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Amount"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Duration
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Duration"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Commission %
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Commission"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="mb-3">
                  <Typography variant="h6" className="formLabel">
                    Unit
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Unit"
                      sx={{ marginRight: '1rem' }}
                    />
                    <Tooltip title="Add" placement="top" arrow>
                      <IconButton className="squareIconButton">
                        <MdOutlineAdd size={16} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <TableContainer
              sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
              aria-label="simple table"
            >
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <TableCell>Loan Type</TableCell>
                    <TableCell align="left">Branch</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left">Commission</TableCell>
                    <TableCell align="left">Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">Home Loan</TableCell>
                    <TableCell align="left">HDFC Ramesh Vihar</TableCell>
                    <TableCell align="left">10,0000</TableCell>
                    <TableCell align="left">10 Yr</TableCell>
                    <TableCell align="left">5%</TableCell>
                    <TableCell align="left">10</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {showHide === 'Both' && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectLoan}
                    onChange={(e) => setSelectLoan(e.target.value)}
                  >
                    <MenuItem value="Select Loan" disabled>
                      Select Loan
                    </MenuItem>
                    <MenuItem value="Education">Education Loan</MenuItem>
                    <MenuItem value="Home">Home Loan</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectBranch}
                    onChange={(e) => setSelectBranch(e.target.value)}
                  >
                    <MenuItem value="Select Branch" disabled>
                      Select Branch
                    </MenuItem>
                    <MenuItem value="HDFC">HDFC</MenuItem>
                    <MenuItem value="ICICI">ICICI</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="mb-3">
                  <Typography variant="h6" className="formLabel">
                    Commission %
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Commission %"
                      sx={{ marginRight: '1rem' }}
                    />
                    <Tooltip title="Add" placement="top" arrow>
                      <IconButton className="squareIconButton">
                        <MdOutlineAdd size={16} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <TableContainer
              sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1.5rem' }}
              aria-label="simple table"
            >
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <TableCell>Loan Type</TableCell>
                    <TableCell align="left">Branch Address</TableCell>
                    <TableCell align="left">Contact Person</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">HDFC</TableCell>
                    <TableCell align="left">Ramesh Vihar, New Delhi</TableCell>
                    <TableCell align="left">Ramesh</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="subtitle1" className="mb-2">
              Turnover Discount
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Loan Type
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectLoan}
                    onChange={(e) => setSelectLoan(e.target.value)}
                  >
                    <MenuItem value="Select Loan" disabled>
                      Select Loan
                    </MenuItem>
                    <MenuItem value="Education">Education Loan</MenuItem>
                    <MenuItem value="Home">Home Loan</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Branch
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value={selectBranch}
                    onChange={(e) => setSelectBranch(e.target.value)}
                  >
                    <MenuItem value="Select Branch" disabled>
                      Select Branch
                    </MenuItem>
                    <MenuItem value="HDFC">HDFC</MenuItem>
                    <MenuItem value="ICICI">ICICI</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Amount
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Amount"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Duration
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Duration"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="">
                  <Typography variant="h6" className="formLabel">
                    Commission %
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type Commission"
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box className="mb-3">
                  <Typography variant="h6" className="formLabel">
                    Unit
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Unit"
                      sx={{ marginRight: '1rem' }}
                    />
                    <Tooltip title="Add" placement="top" arrow>
                      <IconButton className="squareIconButton">
                        <MdOutlineAdd size={16} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <TableContainer
              sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
              aria-label="simple table"
            >
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <TableCell>Loan Type</TableCell>
                    <TableCell align="left">Branch</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Duration</TableCell>
                    <TableCell align="left">Commission</TableCell>
                    <TableCell align="left">Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">Home Loan</TableCell>
                    <TableCell align="left">HDFC Ramesh Vihar</TableCell>
                    <TableCell align="left">10,0000</TableCell>
                    <TableCell align="left">10 Yr</TableCell>
                    <TableCell align="left">5%</TableCell>
                    <TableCell align="left">10</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          className="mainBtn nextBtn"
          endIcon={<HiOutlineArrowNarrowRight />}
          variant="contained"
        >
          Next
        </Button>
      </Box>
    </>
  );
}

function Step4Content({ handleNext, handlePrev }) {
  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <Box>
      <Box variant="Container" className="uploadBox">
        <label htmlFor="contained-button-file" onChange={handleChange}>
          <Input accept="image/*" id="contained-button-file" multiple type="file" />
          <Box>
            <BiCloudUpload className="upladIcon" />
            <Typography variant="h5">Upload Agreement</Typography>
          </Box>
        </label>
      </Box>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button
          onClick={handleNext}
          endIcon={<HiOutlineArrowNarrowRight />}
          className="mainBtn nextBtn"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
}

function Step5Content({ handlePrev }) {
  return (
    <Box>
      <Typography variant="subtitle1" marginBottom={2} sx={{ textDecoration: 'underline' }}>
        Bank Details
      </Typography>
      <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }} marginBottom={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="reviewLabel">
            Bank Name
          </Typography>
          <Typography variant="subtitle2" className="reviewValue">
            HDFC
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="reviewLabel">
            Address
          </Typography>
          <Typography variant="subtitle2" className="reviewValue">
            Ramesh Nagar, New Delhi
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="reviewLabel">
            PAN number
          </Typography>
          <Typography variant="subtitle2" className="reviewValue">
            AHVBGF24653S
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="reviewLabel">
            GSTIN
          </Typography>
          <Typography variant="subtitle2" className="reviewValue">
            GST12453687
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="reviewLabel">
            Commission Structure
          </Typography>
          <Typography variant="subtitle2" className="reviewValue">
            Bank
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" className="reviewLabel">
            Turnover Benefit
          </Typography>
          <Typography variant="subtitle2" className="reviewValue">
            Yes
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" marginBottom={2} sx={{ textDecoration: 'underline' }}>
        Branch Details
      </Typography>
      <TableContainer
        sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
        aria-label="simple table"
      >
        <Table border={1} borderColor="#ebedf2">
          <TableHead>
            <TableRow>
              <TableCell>Branch Name</TableCell>
              <TableCell align="left">Branch Address</TableCell>
              <TableCell align="left">Contact Person</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">HDFC</TableCell>
              <TableCell align="left">Ramesh Vihar, New Delhi</TableCell>
              <TableCell align="left">Ramesh</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="subtitle1" marginBottom={2} sx={{ textDecoration: 'underline' }}>
        Commission Details
      </Typography>
      <TableContainer
        sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
        aria-label="simple table"
      >
        <Table border={1} borderColor="#ebedf2">
          <TableHead>
            <TableRow>
              <TableCell>Loan Type</TableCell>
              <TableCell align="left">Branch</TableCell>
              <TableCell align="left">Commission %</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Home loan</TableCell>
              <TableCell align="left">HDFC Ramesh Vihar</TableCell>
              <TableCell align="left">5%</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="subtitle1" marginBottom={2} sx={{ textDecoration: 'underline' }}>
        Turnover Benefit
      </Typography>
      <TableContainer
        sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
        aria-label="simple table"
      >
        <Table border={1} borderColor="#ebedf2">
          <TableHead>
            <TableRow>
              <TableCell>Loan Type</TableCell>
              <TableCell align="left">Branch</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Commission</TableCell>
              <TableCell align="left">Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="left">Home Loan</TableCell>
              <TableCell align="left">HDFC Ramesh Vihar</TableCell>
              <TableCell align="left">10 Yr</TableCell>
              <TableCell align="left">5%</TableCell>
              <TableCell align="left">10</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: 'flex', paddingTop: '1.5rem' }}>
        <Button
          onClick={handlePrev}
          startIcon={<HiOutlineArrowNarrowLeft />}
          className="mainBtn previousBtn"
        >
          Back
        </Button>
        <Button endIcon={<HiOutlineArrowNarrowRight />} className="mainBtn nextBtn">
          Finish
        </Button>
      </Box>
    </Box>
  );
}

export default EditAgreement;
