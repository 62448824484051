import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function AddEditMaster() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  return (
    <Page title="Partner Management System || Master">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info={rowId ? 'Edit master' : 'Add new Master Parents'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Box className="fixedBox">
                <Box className="formRowHolder">
                  <Typography variant="h6" className="formLabel">
                    Master Name
                  </Typography>
                  <TextField
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    placeholder="Type master name..."
                  />
                </Box>
                <Box className="formRowHolder">
                  <Typography variant="h6" className="formLabel">
                    Master Category
                  </Typography>
                  <Select
                    fullWidth
                    inputProps={{
                      className: 'textInput'
                    }}
                    value=" "
                  >
                    <MenuItem value=" " disabled>
                      Select
                    </MenuItem>
                    <MenuItem> Menu 1</MenuItem>
                    <MenuItem> Menu 1</MenuItem>
                  </Select>
                </Box>
                {masterValue.map((item, index) => (
                  <Box key={`${item.masterValue}_${index}`} className="formRowHolder">
                    <Typography variant="h6" className="formLabel">
                      Master Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type master value..."
                      value={item.masterValue}
                      onChange={(e) => {
                        const prevMaster = masterValue;
                        prevMaster[index] = e.target.value;
                        setMasterValue(prevMaster);
                        setRefreshComp(new Date());
                      }}
                    />
                    {index < masterValue.length - 1 ? (
                      <Box className="addMoreIcon">
                        <Tooltip title="Add more" placement="top" arrow>
                          <IconButton
                            className="squareIconButton"
                            onClick={(e) => removeMasterValue(index)}
                          >
                            <Icon icon={remove} width={16} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Box className="addMoreIcon">
                        <Tooltip title="Add more" placement="top" arrow>
                          <IconButton
                            className="squareIconButton"
                            onClick={(e) => addMoreMasterValue(e)}
                          >
                            <Icon icon={add} width={16} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                ))}
                <Box className="formRowHolder">&nbsp;</Box>
                <Box className="formRowHolder">
                  <Button variant="contained">Save</Button>
                </Box>
              </Box>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditMaster;
