import { useState } from 'react';
// material
import {
  Container,
  Stack,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton
} from '@mui/material';
// components
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import CampaignIcon from '@mui/icons-material/Campaign';
import InboxIcon from '@mui/icons-material/Inbox';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import WarningIcon from '@mui/icons-material/Warning';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArticleIcon from '@mui/icons-material/Article';
import { CgFacebook } from 'react-icons/cg';
import { MdOutlineLocalLaundryService } from 'react-icons/md';
import { AiFillInstagram, AiFillYoutube } from 'react-icons/ai';
import BrudCrumbs from '../../components/BreadCrumbs';
import PageTitle from '../../components/PageHeading';
import Page from '../../components/Page';

export default function Integration() {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  return (
    <Page title="Partner Management System || Integration">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Integration" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs="12" sm="4" md="3" marginBottom={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <CgFacebook className="cardIconIcon facebook" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Facebook
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs="12" sm="4" md="3" marginBottom={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <AiFillInstagram className="cardIconIcon instagram" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Instagram
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs="12" sm="4" md="3" marginBottom={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <AiFillYoutube className="cardIconIcon youtube" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Youtube
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs="12" sm="4" md="3" marginBottom={3}>
              <Box className="cardOuter cardOuterGreenBorder">
                <Box className="cardTop" container>
                  <Box className="cardIconLeft">
                    <Typography variant="caption" className="cardIconInner">
                      <MdOutlineLocalLaundryService className="cardIconIcon callCenter" />
                    </Typography>
                  </Box>
                  <Box className="cardIconRight">
                    <Typography className="" variant="subtitle2" component="div" mb={1}>
                      Call Center
                    </Typography>
                    <Typography className="cardText">10 April, 2022</Typography>
                    <Typography className="cardText">Amit Mishra</Typography>
                  </Box>
                </Box>
                <Box className="cardBottom">
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="6">
                      <IconButton className="cardIconFooter">
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs="6" textAlign="right">
                      <Switch
                        {...label}
                        defaultChecked
                        color="success"
                        disableRipple="disable"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}
