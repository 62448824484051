import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  Divider,
  TextareaAutosize
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: 4
  }
}));

function RaiseInvoice() {
  const [loanInterested, setLoanInterested] = useState(' ');
  return (
    <Page title="Partner Management System || Invoice">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Invoice Details" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Box className="formRowHolder fullrow">
                <Typography>
                  <strong>Application Details</strong>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Bank
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Hdfc"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Application ID
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="1023456"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Application Name
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Ramesh"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Loan Type
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Home Loan"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Amount Applied
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="20,00,000"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Amount Sanctioned
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="15,00,000"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Amount Disbursed
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="15,00,000"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: '1.5rem', marginTop: '1rem' }} />
              <Box className="formRowHolder fullrow">
                <Typography>
                  <strong>Lead Owner Details</strong>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Lead Source Type
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Sales Team"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Lead Owner
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Mohan"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Lead Co Owner
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Mahesh"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Applicable Commission
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="5%"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Lead Owner Share
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Lead Co owner Share
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: '1.5rem', marginTop: '1rem' }} />
              <Box className="formRowHolder fullrow">
                <Typography>
                  <strong>Processing Fee details</strong>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Total Processing Fee
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Bank Waiver, if any
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Applicant Share
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Company Share
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Processing Fee Status
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Fee to be deducted from Invoice
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: '1.5rem', marginTop: '1rem' }} />
              <Box className="formRowHolder fullrow">
                <Typography>
                  <strong>Invoice Calculation</strong>
                </Typography>
              </Box>
              <Grid container spacing={3}>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Gross Invoice Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Deduction
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Adjusted Invoice Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      GST
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" sm="6" md="4">
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Net Invoice Value
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value="Lorem"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Scrollbar>
            <Box className="mb-4" textAlign="">
              <Button variant="contained" className="mainBtn">
                Generate Invoice
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default RaiseInvoice;
