import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import dashboard from '@iconify/icons-ic/dashboard';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-ic/card-membership';
import access from '@iconify/icons-ic/lock-open';
import fileExclamationFilled from '@iconify/icons-ant-design/file-exclamation-filled';
import { FaUserTie, FaFileInvoiceDollar } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { BsNewspaper } from 'react-icons/bs';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ReceiptIcon from '@mui/icons-material/Receipt';
import GroupIcon from '@mui/icons-material/Group';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import GavelIcon from '@mui/icons-material/Gavel';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import { IoNewspaperSharp } from 'react-icons/io';
import { JourneyIcon } from '../../vector/index';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'Prospect',
    path: '/prospect',
    icon: getIcon(dashboard)
  },
  {
    title: 'Call center',
    path: '/call-center',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Leads',
    path: '/leads',
    icon: getIcon(peopleFill)
  },
  {
    title: 'Log In',
    path: '/login',
    icon: getIcon(access)
  },
  {
    title: 'Invoice',
    path: '/invoice',
    icon: <ReceiptIcon />
  },
  {
    title: 'Revenue',
    path: '/revenue',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'Rejected',
    path: '/rejected',
    icon: <ThumbDownAltIcon />
  },
  {
    title: 'On Boarding',
    path: '/onboarding',
    icon: <ContactPageIcon />
  },
  {
    title: 'Agreement',
    path: '/agreement',
    icon: <GavelIcon />
  },
  {
    title: 'Partners',
    path: '/partners',
    icon: <AccountBoxIcon />
  },
  {
    title: 'Customer',
    path: '/customer',
    icon: <SupervisedUserCircleIcon />
  },
  // {
  //   title: 'Admin',
  //   path: '/admin',
  //   icon: getIcon(peopleFill)
  // }
  {
    title: 'Integration',
    path: '/integration',
    icon: <IntegrationInstructionsIcon />
  },
  {
    title: 'Master',
    path: '/master',
    icon: <DomainVerificationIcon />
  },
  {
    title: 'Users',
    path: '/users',
    icon: <GroupIcon />
  }
];

export default sidebarConfig;
