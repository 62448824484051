import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled,
  tableCellClasses
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import { MdIncompleteCircle, MdOutlineDateRange } from 'react-icons/md';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: 4
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function ViewProspect() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <Page title="Partner Management System || Prospect">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="View Prospect" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Box className="viewLeadCard" mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <AiOutlineClockCircle className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Prospect Id
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegAddressCard className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Customer ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <BiBoltCircle className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Application ID
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <BsCardHeading className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Current Stage
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <MdOutlineDateRange className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Current Status
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <MdIncompleteCircle className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Last Update Date
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegUser className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Sales Person
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegUser className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Assigned On
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="displayFlex">
                    <FaRegUser className="viewLabelIcon" size={24} />
                    <Box>
                      <Typography variant="body2" className="labelText">
                        Assigned By
                      </Typography>
                      <Typography variant="subtitle2" className="labelValue">
                        Lorem
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs="12" sm="6" md="6" mb={3}>
                <Box className="viewLeadCard">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsTelephone className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <HiOutlineMail className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Email
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsCardHeading className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Interested in
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiSwapBag className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Amount
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs="12" sm="6" md="6" mb={3}>
                <Box className="viewLeadCard">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <MdOutlineDateRange className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Company Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <SiOpensourceinitiative className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Company Source
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Prospect Count
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Prospect Date
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Assigned On
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Assigned By
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box> */}
            <TableContainer
              sx={{ minWidth: 800, overflow: 'auto', marginBottom: '2rem' }}
              aria-label="simple table"
            >
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Call Date</TableCell>
                    <TableCell align="left">Call Agent</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">27 April, 2022</TableCell>
                    <TableCell align="left">Ankita</TableCell>
                    <TableCell align="left">Pending</TableCell>
                    <TableCell align="left">Document not correct</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewProspect;
