import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard/DashboardLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import AddEditProspect from './pages/Prospect/AddEditProspect';
import AddEditChildMaster from './pages/MasterChild/AddEditChildMaster';
import AddEditUser from './pages/Users/AddEditUser';
import ProspectList from './pages/Prospect/ProspectList';
import CallCenterList from './pages/CallCenter/CallCenterList';
import UpdateCallCenter from './pages/CallCenter/UpdateCallCenter';
import Leads from './pages/Leads/Leads';
import RejectedList from './pages/Rejected/RejectedList';
import OnBoarding from './pages/OnBoarding/OnBoarding';
import AddEditLeads from './pages/Leads/AddeditLeads';
import AddEditOnBoarding from './pages/OnBoarding/AddeditOnBoarding';
import UploadLeadProspect from './pages/Prospect/UploadLeadProspect';
import UpdateLeads from './pages/Leads/UpdateLeads';
import SubmitLeads from './pages/Leads/SubmitLeads';
import ViewProspect from './pages/Prospect/ViewProspect ';
import ViewLeads from './pages/Leads/ViewLeads';
import UpdateLogIn from './pages/LogIn/UpdateLogIn';
import RevenueList from './pages/Revenue/RevenueList';
import UpdateRevenue from './pages/Revenue/UpdateRevenue';
import ReapplyRejected from './pages/Rejected/ReapplyRejected';
import Users from './pages/Users/Users';
import MasterList from './pages/Master/MasterList';
import AddEditMaster from './pages/Master/AddEditMaster';
import Integration from './pages/Integration/Integration';
import Invoice from './pages/Invoice/Invoice';
import UpdateInvoice from './pages/Invoice/UpdateInvoice';
import PartnersList from './pages/Partners/PartnersList';
import AddEditPartners from './pages/Partners/AddEditPartners';
import CustomerList from './pages/Customer/CustomerList';
import LogIn from './pages/LogIn/LogIn';
import DecisionLogIn from './pages/LogIn/DecisionLogIn';
import RaiseInvoice from './pages/Invoice/RaiseInvoice';
import PaymentInvoice from './pages/Invoice/PaymentInvoice';
import ViewLogin from './pages/LogIn/ViewLogin';
import ViewRevenue from './pages/Revenue/ViewRevenue';
import ReviewOnBoarding from './pages/OnBoarding/ReviewOnBoarding';
import SetCommissionOnBoarding from './pages/OnBoarding/SetCommissionOnBoarding';
import BusinessReviewOnBoarding from './pages/OnBoarding/BusinessReviewOnBoarding';
import Agreement from './pages/Agreement/Agreement';
import AddAgreement from './pages/Agreement/AddAgreement';
import ViewAgreement from './pages/Agreement/ViewAgreement';
import EditAgreement from './pages/Agreement/EditAgreement';
import ViewRejected from './pages/Rejected/ViewRejected';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        {
          path: '',
          element: <DashboardApp />,
          children: [{ path: '', element: <DashboardApp /> }]
        }
      ]
    },
    {
      path: '/prospect',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <ProspectList /> },
        { path: 'add', element: <AddEditProspect /> },
        { path: 'edit/:rowId', element: <AddEditProspect /> },
        { path: 'view', element: <ViewProspect /> },
        { path: 'upload', element: <UploadLeadProspect /> }
      ]
    },
    {
      path: '/onboarding',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <OnBoarding /> },
        { path: 'add', element: <AddEditOnBoarding /> },
        { path: 'review', element: <ReviewOnBoarding /> },
        { path: 'business-review', element: <BusinessReviewOnBoarding /> },
        { path: 'set-commission', element: <SetCommissionOnBoarding /> },
        { path: 'edit/:rowId', element: <AddEditOnBoarding /> }
      ]
    },
    {
      path: '/invoice',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Invoice /> },
        { path: 'raise-invoice', element: <RaiseInvoice /> },
        { path: 'payment-invoice', element: <PaymentInvoice /> },
        { path: 'update', element: <UpdateInvoice /> }
      ]
    },
    {
      path: '/call-center',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <CallCenterList /> },
        { path: 'update', element: <UpdateCallCenter /> },
        { path: 'add', element: <AddEditChildMaster /> },
        { path: 'edit/:rowId', element: <AddEditChildMaster /> }
      ]
    },
    {
      path: '/revenue',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <RevenueList /> },
        { path: 'add', element: <AddEditUser /> },
        { path: 'update', element: <UpdateRevenue /> },
        { path: 'view', element: <ViewRevenue /> },
        { path: 'edit/:rowId', element: <AddEditUser /> }
      ]
    },
    {
      path: '/leads',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Leads /> },
        { path: 'add', element: <AddEditLeads /> },
        { path: 'submit', element: <SubmitLeads /> },
        { path: 'update', element: <UpdateLeads /> },
        { path: 'view', element: <ViewLeads /> },
        { path: 'edit/:rowId', element: <AddEditLeads /> }
      ]
    },
    {
      path: '/rejected',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <RejectedList /> },
        { path: 'reapply', element: <ReapplyRejected /> },
        { path: 'view', element: <ViewRejected /> }
      ]
    },
    {
      path: '/login',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <LogIn /> },
        { path: 'decision', element: <DecisionLogIn /> },
        { path: 'view', element: <ViewLogin /> },
        { path: 'update', element: <UpdateLogIn /> }
      ]
    },
    {
      path: '/integration',
      element: <DashboardLayout />,
      children: [{ path: '', element: <Integration /> }]
    },
    {
      path: '/master',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <MasterList /> },
        { path: 'add', element: <AddEditMaster /> },
        { path: 'edit/:rowId', element: <AddEditMaster /> }
      ]
    },
    {
      path: '/users',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Users /> },
        { path: 'add', element: <AddEditUser /> },
        { path: 'edit/:rowId', element: <AddEditUser /> }
      ]
    },
    {
      path: '/partners',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <PartnersList /> },
        { path: 'add', element: <AddEditPartners /> }
      ]
    },
    {
      path: '/customer',
      element: <DashboardLayout />,
      children: [{ path: '', element: <CustomerList /> }]
    },
    {
      path: '/agreement',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Agreement /> },
        { path: 'add', element: <AddAgreement /> },
        { path: 'view', element: <ViewAgreement /> },
        { path: 'edit', element: <EditAgreement /> }
      ]
    },
    {
      path: '/',
      element: <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
