import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  Paper,
  Checkbox
} from '@mui/material';
import styled from '@emotion/styled';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function AddEditUser() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [showHide, setShowHide] = useState('1');
  const handleShowHide = (event) => {
    const getUserType = event.target.value;
    setShowHide(getUserType);
  };
  const [userType, setUserType] = useState('1');

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  return (
    <Page title="Partner Management System || User">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info={rowId ? 'Edit User' : 'Add New User'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Box className="fixedBox">
                <Typography marginBottom={3}>
                  <strong>General</strong>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Box className="formRowHolder fullrow">
                      <Typography variant="h6" className="formLabel">
                        User Type
                      </Typography>
                      <Select
                        fullWidth
                        inputProps={{
                          className: 'textInput'
                        }}
                        value={userType}
                        onChange={(e) => {
                          handleShowHide(e);
                          setUserType(e.target.value);
                        }}
                      >
                        {/* <MenuItem disabled="disabled" value="0">
                          User Type
                        </MenuItem> */}
                        <MenuItem value="1">Internal</MenuItem>
                        <MenuItem value="2">External</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                </Grid>
                {showHide === '1' && (
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          User Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type user name"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Mobile
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type mobile number"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type email"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Branch
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select Branch
                          </MenuItem>
                          <MenuItem selected="selected" disabled="disabled" value="">
                            Branch{' '}
                          </MenuItem>
                          <MenuItem>Mumbai</MenuItem>
                          <MenuItem>Pune</MenuItem>
                          <MenuItem>Nagpur</MenuItem>
                          <MenuItem>Ahmedabad</MenuItem>
                          <MenuItem>Baroda</MenuItem>
                          <MenuItem>Surat</MenuItem>
                          <MenuItem>Jaipur</MenuItem>
                          <MenuItem>Delhi NCR</MenuItem>
                          <MenuItem>Bengaluru</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Department
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select Department
                          </MenuItem>
                          <MenuItem selected="selected" disabled="disabled" value="">
                            User Department{' '}
                          </MenuItem>
                          <MenuItem>Loan</MenuItem>
                          <MenuItem>Credit</MenuItem>
                          <MenuItem>Field</MenuItem>
                          <MenuItem>Legal</MenuItem>
                          <MenuItem>Valuation</MenuItem>
                          <MenuItem>Sales</MenuItem>
                          <MenuItem>Branch</MenuItem>
                          <MenuItem>Data Entry</MenuItem>
                          <MenuItem>Fraud</MenuItem>
                          <MenuItem>KYC</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Department Level
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select Department Level
                          </MenuItem>
                          <MenuItem> L1</MenuItem>
                          <MenuItem> L2</MenuItem>
                          <MenuItem> L3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          Reporting Manager
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select Reporting Manager
                          </MenuItem>
                          <MenuItem>Manager1</MenuItem>
                          <MenuItem>Manager2</MenuItem>
                          <MenuItem>Manager3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Access
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select Access
                          </MenuItem>
                          <MenuItem> Menu 1</MenuItem>
                          <MenuItem> Menu 1</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {showHide === '2' && (
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          User Organization
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select User Organization
                          </MenuItem>
                          <MenuItem>Organization1</MenuItem>
                          <MenuItem>Organization2</MenuItem>
                          <MenuItem>Organization3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          User Name
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type user name"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Mobile
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type mobile number..."
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Email
                        </Typography>
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Type email..."
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          Reporting Manager
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select
                          </MenuItem>
                          <MenuItem>Manager1</MenuItem>
                          <MenuItem>Manager2</MenuItem>
                          <MenuItem>Manager3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box>
                        <Typography variant="h6" className="formLabel">
                          Internal Sales Manager
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select
                          </MenuItem>
                          <MenuItem>Manager1</MenuItem>
                          <MenuItem>Manager2</MenuItem>
                          <MenuItem>Manager3</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="formRowHolder fullrow">
                        <Typography variant="h6" className="formLabel">
                          Access
                        </Typography>
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value=" "
                        >
                          <MenuItem value=" " disabled>
                            Select
                          </MenuItem>
                          <MenuItem> Menu 1</MenuItem>
                          <MenuItem> Menu 1</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Box className="formRowHolder">
                  <Button variant="contained">Save</Button>
                </Box>
              </Box>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditUser;
