import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { BsWindowDock, BsCardHeading, BsTelephone } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineIdcard } from 'react-icons/ai';
import { RiBankCardLine } from 'react-icons/ri';
import { FaRegAddressCard, FaRegUser } from 'react-icons/fa';
import { CgCalendarDates } from 'react-icons/cg';
import { HiOutlineMail } from 'react-icons/hi';
import { SiOpensourceinitiative } from 'react-icons/si';
import { GiSwapBag, GiModernCity } from 'react-icons/gi';
import { MdIncompleteCircle, MdOutlineDateRange, MdOutlinePreview } from 'react-icons/md';
import { BiCloudUpload, BiCloudDownload, BiBoltCircle } from 'react-icons/bi';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function ViewLeads() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');
  const [showProspectDetail, setShowProspectDetail] = useState(false);
  const [showCallCenterDetail, setShowCallCenterDetail] = useState(false);
  const [showLeadsDetail, setShowLeadsDetail] = useState(false);

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <Page title="Partner Management System || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Application Progress Report" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Grid container spacing={3} mb={3}>
              <Grid item xs="12" sm="6" md="6">
                <Typography variant="body2" className="headingLineUp headingLineUpOut">
                  Loan Requirement
                </Typography>
                <Box className="viewLeadCard">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Name
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsTelephone className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Phone
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <HiOutlineMail className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Email
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <BsCardHeading className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Interested in
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiSwapBag className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Loan Amount
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <GiModernCity className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            City
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs="12" sm="6" md="6">
                <Typography variant="body2" className="headingLineUp headingLineUpOut">
                  Current Status
                </Typography>
                <Box className="viewLeadCard">
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <MdOutlineDateRange className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Lead DateApplication ID
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <SiOpensourceinitiative className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Source
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Application URL
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Sales Person
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Completion %
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className="displayFlex">
                        <FaRegUser className="viewLabelIcon" size={24} />
                        <Box>
                          <Typography variant="body2" className="labelText">
                            Last Update
                          </Typography>
                          <Typography variant="subtitle2" className="labelValue">
                            Lorem
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box> */}
            <Box className="viewLeadCard" mb={3}>
              <Typography variant="body2" className="headingLineUp">
                Document Checklist
              </Typography>
              <Box className="displayFlex">
                <Box className="uploadFilePreview uploadFilePreviewGreen">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">Aadhar Card</Typography>
                </Box>
                <Box className="uploadFilePreview uploadFilePreviewGreen">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">Pan Card</Typography>
                </Box>
                <Box className="uploadFilePreview uploadFilePreviewRed">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">Address Proof</Typography>
                </Box>
                <Box className="uploadFilePreview uploadFilePreviewGreen">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">ITR 2019</Typography>
                </Box>
                <Box className="uploadFilePreview uploadFilePreviewRed">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">ITR 2020</Typography>
                </Box>
                <Box className="uploadFilePreview uploadFilePreviewGreen">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">Salary Slip March</Typography>
                </Box>
                <Box className="uploadFilePreview uploadFilePreviewRed">
                  <MdOutlinePreview className="ImageViewIcon" />
                  <Typography variant="body2">Photo</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="viewLeadCard" mb={4}>
              <Typography variant="body2" className="headingLineUp">
                Activity Log
              </Typography>
              <Box container>
                <Box className="statusBox">
                  <Typography variant="body2">Current Status:</Typography>
                  <Typography variant="subtitle2" className="disbursedText">
                    Leads (U-Doc)
                  </Typography>
                </Box>
              </Box>
              <Box className="timelineB">
                <Box className="timelineContentB timelineContentBGreen">
                  <Typography variant="subtitle1" className="date">
                    Prospect
                  </Typography>
                  <Typography variant="body2" className="">
                    Start Date: <strong>27 April, 2022</strong>
                  </Typography>
                  <Typography variant="body2" className="" mb={1}>
                    End Date: <strong>30 April, 2022</strong>
                  </Typography>
                  <Button
                    className="mainBtnSmall"
                    onClick={(e) => {
                      setShowProspectDetail(true);
                      setShowCallCenterDetail(false);
                      setShowLeadsDetail(false);
                    }}
                  >
                    More
                  </Button>
                </Box>
                <Box className="timelineContentB timelineContentBGreen">
                  <Typography variant="subtitle1" className="date">
                    Call Center
                  </Typography>
                  <Typography variant="body2" className="">
                    Start Date: <strong>27 April, 2022</strong>
                  </Typography>
                  <Typography variant="body2" className="" mb={1}>
                    End Date: <strong>30 April, 2022</strong>
                  </Typography>
                  <Button
                    className="mainBtnSmall"
                    onClick={(e) => {
                      setShowProspectDetail(false);
                      setShowCallCenterDetail(true);
                      setShowLeadsDetail(false);
                    }}
                  >
                    More
                  </Button>
                </Box>
                <Box className="timelineContentB timelineContentBOrange">
                  <Typography variant="subtitle1" className="date">
                    Leads
                  </Typography>
                  <Typography variant="body2" className="">
                    Start Date: <strong>27 April, 2022</strong>
                  </Typography>
                  <Typography variant="body2" className="" mb={1}>
                    End Date: <strong>30 April, 2022</strong>
                  </Typography>
                  <Button
                    className="mainBtnSmall"
                    onClick={(e) => {
                      setShowProspectDetail(false);
                      setShowCallCenterDetail(false);
                      setShowLeadsDetail(true);
                    }}
                  >
                    More
                  </Button>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Log In
                  </Typography>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Revenue
                  </Typography>
                </Box>
                <Box className="timelineContentB">
                  <Typography variant="subtitle1" className="date">
                    Rejected
                  </Typography>
                </Box>
              </Box>
              {showProspectDetail ? (
                <Box className="viewLeadCard" mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Prospect ID
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Campaign Name
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Source
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Leads Assigned on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Assigned by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Generated on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Status
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Agents Comments
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Sales Person
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {showCallCenterDetail ? (
                <Box className="viewLeadCard" mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Application ID
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Campaign Name
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Source
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Leads Assigned on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Assigned by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Generated on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified on
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Qualified by
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Lead Status
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Agents Comments
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box className="">
                        <Typography variant="body2" className="labelText">
                          Sales Person
                        </Typography>
                        <Typography variant="subtitle2" className="labelValue">
                          Lorem
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {showLeadsDetail ? (
                <TableContainer
                  sx={{ minWidth: 800, overflow: 'auto', marginTop: '1.5rem' }}
                  aria-label="simple table"
                >
                  <Table border={1} borderColor="#ebedf2">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left">Comments</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">27 April, 2022</TableCell>
                        <TableCell align="left">Pendding</TableCell>
                        <TableCell align="left">Document Remaining</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : null}
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default ViewLeads;
