import { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import { CgAssign } from 'react-icons/cg';
import { BiSync, BiCloudUpload } from 'react-icons/bi';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  TextField,
  Button,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function ProspectToolbar({ numSelected, filterName, onFilterName, parent }) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [openSyncLeads, setOpenSyncLeads] = useState(false);
  const [sourceValue, setSourceValue] = useState('facebook');
  const [openAssign, setOpenAssign] = useState(false);
  const [assignValue, setAssignValue] = useState(' ');
  console.log(filterDatesRange);
  return (
    <>
      {isOpenFilter && (
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="h6" className="formLabel">
                Source
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Source
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <Typography variant="h6" className="formLabel">
                Dates
              </Typography>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="formLabel">
                Location
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Location
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" className="formLabel">
                Status
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Status
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45
        }}
      >
        <Box>
          <Tooltip title="New Prospect" placement="top" arrow>
            <IconButton className="squareIconButton" onClick={(e) => navigate(`/${parent}/add`)}>
              <Icon icon={add} width={16} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip
            title="Upload"
            placement="top"
            arrow
            onClick={(e) => navigate(`/${parent}/upload`)}
          >
            <IconButton className="squareIconButton">
              <BiCloudUpload size={16} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Sync Leads" placement="top" arrow onClick={(e) => setOpenSyncLeads(true)}>
            <IconButton className="squareIconButton">
              <BiSync size={16} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Assign" placement="top" arrow onClick={(e) => setOpenAssign(true)}>
            <IconButton className="squareIconButton">
              <CgAssign size={16} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Prospect"
            classes={{
              root: 'searchHolder',
              input: 'searchInput'
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Filter list">
            <IconButton
              onClick={(e) => {
                if (isOpenFilter) {
                  openFilter(false);
                } else {
                  openFilter(true);
                }
              }}
            >
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip>
        </Box>
      </RootStyle>
      <Dialog open={openSyncLeads} onClose={(e) => setOpenSyncLeads(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">Select the Source you want to Sync</Typography>
          <IconButton onClick={(e) => setOpenSyncLeads(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={sourceValue}
            onChange={(e) => setSourceValue(e.target.value)}
          >
            <FormControlLabel value="facebook" control={<Radio />} label="Facebook" />
            <FormControlLabel
              value="callCentre"
              control={<Radio />}
              label="Call Centre Application"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenSyncLeads(false)} variant="contained">
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openAssign} onClose={(e) => setOpenAssign(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography variant="h6">27 Prospect Selected</Typography>
          <IconButton onClick={(e) => setOpenAssign(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            inputProps={{
              className: 'textInput'
            }}
            value={assignValue}
            onChange={(e) => setAssignValue(e.target.value)}
          >
            <MenuItem value=" " disabled>
              Select Agent Name
            </MenuItem>
            <MenuItem value="Manoj">Manoj Kumar</MenuItem>
            <MenuItem value="Vinod">Vinod Saini</MenuItem>
            <MenuItem value="Mahendra">Mahendra Sharma</MenuItem>
            <MenuItem value="Sanjay">Sanjay Singh</MenuItem>
            <MenuItem value="Sujeet">Sujeet Verma</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenAssign(false)} variant="contained">
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
