import { filter } from 'lodash';
import PropTypes from 'prop-types';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  tableCellClasses,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  DialogContent
} from '@mui/material';
import { Box } from '@mui/system';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { AiOutlineClose } from 'react-icons/ai';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import BrudCrumbs from '../../components/BreadCrumbs';
import { getIcon, JourneyIcon } from '../../vector/index';
import PageTitle from '../../components/PageHeading';
//
import USERLIST from '../../_mocks_/user';
import { fDate } from '../../utils/formatTime';
import RejectedToolbar from './RejectedToolbar';
// import SanctionedToolbar from './SanctionedToolbar';
// import OppoutunityToolbar from './OppoutunityToolbar';
// import SalesToolbar from './SalesToolbar';
// import ProspectToolbar from './ProspectToolbar';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'leadId', label: 'APPLICATION ID', alignRight: false },
  { id: 'created', label: 'Submission Date', alignRight: false },
  { id: 'name', label: 'NAME', alignRight: false },
  { id: 'loanType', label: 'Product', alignRight: false },
  { id: 'city', label: 'City', alignRight: false },
  { id: 'amount', label: 'Amount Requested', alignRight: false },
  { id: 'bankName', label: 'Bank', alignRight: false },
  { id: 'status', label: 'status', alignRight: false },
  { id: 'reason', label: 'Reason', alignRight: false },
  { id: 'created', label: 'Last Updated', alignRight: false },
  { id: '', label: 'ACTION' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
/**
 * Represents a book.
 * @constructor
 * @param {string} title - The title of the book.
 * @param {string} author - The author of the book.
 */
export default function RejectedList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10000);

  const [openRejected, setOpenRejected] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const columns = [
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    { field: 'calldate', headerName: 'CALL DATE', width: 210 },
    { field: 'callagent', headerName: 'CALL AGENT', width: 180 },
    { field: 'calltime', headerName: 'CALL TIME', width: 220 },
    { field: 'callstatus', headerName: 'CALL STATUS', width: 200 },
    { field: 'comments', headerName: 'COMMENTS', width: 200 }
  ];
  const rows = [
    {
      id: 1,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    },
    {
      id: 2,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    },
    {
      id: 3,
      calldate: 'Lorem',
      callagent: 'Lorem',
      calltime: 'Lorem',
      callstatus: 'Lorem',
      comments: 'Lorem'
    }
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      fontSize: 13,
      fontWeight: 'normal',
      padding: '6px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 11,
      padding: 4
    }
  }));
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  return (
    <Page title="Partner Management System || Rejected">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Rejection Cases" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <RejectedToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
              parent="rejected"
            />

            <Scrollbar>
              <TableContainer
                sx={{ minWidth: 800, height: '70vh', overflow: 'auto', marginBottom: '2rem' }}
              >
                <Table border={1} borderColor="#ebedf2">
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    isCheckBox="true"
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {
                          leadId,
                          id,
                          amount,
                          name,
                          name2,
                          loanType,
                          status,
                          users,
                          randomPhoneNumber,
                          amount2,
                          source,
                          reason,
                          city,
                          created,
                          bankName,
                          isVerified
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <StyledTableRow
                            hover
                            key={id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              />
                            </TableCell>

                            <StyledTableCell align="left">{leadId}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            <StyledTableCell align="left">{name}</StyledTableCell>
                            <StyledTableCell align="left">{loanType}</StyledTableCell>
                            <StyledTableCell align="left">{city}</StyledTableCell>
                            <StyledTableCell align="left">{amount}</StyledTableCell>
                            <StyledTableCell align="left">{bankName}</StyledTableCell>
                            <StyledTableCell align="left">
                              <Label
                                variant="ghost"
                                color={
                                  (status === 'error' && 'error') ||
                                  (status === 'pending' && 'warning') ||
                                  'success'
                                }
                              >
                                {sentenceCase(status)}
                              </Label>
                            </StyledTableCell>
                            <StyledTableCell align="left">{reason}</StyledTableCell>
                            <StyledTableCell align="left">{fDate(created)}</StyledTableCell>
                            {/* <StyledTableCell align="left">
                              {(status === 'error' && 'Document not found') ||
                                (status === 'pending' && 'file in process') ||
                                'success'}
                            </StyledTableCell> */}

                            <StyledTableCell align="" width={80}>
                              <UserMoreMenu
                                rowId={id}
                                parent="rejected"
                                setOpenRejected={setOpenRejected}
                              />
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <StyledTableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <StyledTableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </StyledTableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
          <Dialog open={openRejected} onClose={(e) => setOpenRejected(false)} fullWidth>
            <Box
              display="flex"
              justifyContent="space-between"
              backgroundColor="#166bac"
              color="white"
            >
              <Box>
                <DialogTitle>Update Status</DialogTitle>
              </Box>
              <Button onClick={(e) => setOpenRejected(false)}>
                <AiOutlineClose color="white" />
              </Button>
            </Box>
            <DialogContent>
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel id="demo-select-small">Update Status</InputLabel>
                  <Select
                    id="demo-simple-select"
                    fullWidth
                    value={statusValue}
                    label="Status"
                    onChange={(e) => setStatusValue(e.target.value)}
                  >
                    <MenuItem value="noContact">No Contact</MenuItem>
                    <MenuItem value="invalidNumber">Invalid Number</MenuItem>
                    <MenuItem value="abort">Abort</MenuItem>
                    <MenuItem value="contacted">Contacted</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box mb={2}>
                <TextField
                  id="outlined-basic"
                  label="comments"
                  variant="outlined"
                  multiline
                  fullWidth
                />
              </Box>
              <Box height={100}>
                <DataGrid
                  headerHeight={40}
                  rowHeight={38}
                  className="customDataGrid globalDataGridRoot"
                  rows={rows}
                  columns={columns}
                  pageSize={100}
                  hideFooter
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={(e) => setOpenRejected(false)} variant="contained">
                SUBMIT
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>
    </Page>
  );
}
