import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-ic/drag-handle';
import submit from '@iconify/icons-ant-design/enter-outline';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { CgNotes, CgEnter, CgEditBlackPoint } from 'react-icons/cg';
import { FiEdit, FiEye } from 'react-icons/fi';
import {
  MdSettingsApplications,
  MdNotifications,
  MdOutlineManageAccounts,
  MdModeEdit,
  MdOutlineSendToMobile,
  MdPayments,
  MdReviews,
  MdFormatShapes,
  MdPendingActions
} from 'react-icons/md';
import {
  AiOutlineInfoCircle,
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineCheck,
  AiFillEye
} from 'react-icons/ai';
import { BsFillReplyAllFill } from 'react-icons/bs';
import { FaRegMoneyBillAlt, FaFileInvoiceDollar } from 'react-icons/fa';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Box
} from '@mui/material';
import styled from '@emotion/styled';

// ----------------------------------------------------------------------

export default function UserMoreMenu({
  rowId,
  parent,
  setOpenStatus,
  setOpenOpportunity,
  setOpenSanctioned,
  setOpenRejected,
  setOpenSendMsg
}) {
  const navigate = useNavigate();
  // Styled icon button in Action
  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    margin: '0 3px',
    [`&:hover`]: {
      color: theme.palette.primary.main
    }
  }));

  if (parent === 'leads') {
    return (
      <>
        <Tooltip
          title="Application"
          placement="top"
          arrow
          onClick={(e) => {
            window.open(`http://offline.kalolytic.com/`, `_blank`);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdFormatShapes size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/view`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Submit"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/submit`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <CgEnter size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'login') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/view`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Decision"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/decision`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdPendingActions size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'revenue') {
    return (
      <>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/view`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Send Message"
          placement="top"
          arrow
          onClick={(e) => {
            setOpenSendMsg(true);
          }}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdOutlineSendToMobile size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'rejected') {
    return (
      <>
        <Tooltip
          title="Re Apply"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/reapply`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <BsFillReplyAllFill size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/view`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'call-center') {
    return (
      <>
        <Tooltip title="Complete Form" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <CgNotes size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Update Status" placement="top" arrow>
          <StyledIconButton
            className="squareIconButton actionButton"
            onClick={(e) => setOpenStatus(true)}
          >
            <AiOutlineInfoCircle size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Close" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <AiOutlineClose size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'on-boarding') {
    return (
      <>
        <Tooltip
          title="Review"
          placement="top"
          arrow
          onClick={(e) => navigate(`/onboarding/review`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdReviews size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Set Commission"
          placement="top"
          arrow
          onClick={(e) => navigate(`/onboarding/set-commission`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FaRegMoneyBillAlt size={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="Activate" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <AiOutlineCheck size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'invoice') {
    return (
      <Box sx={{ display: 'flex' }}>
        <Tooltip
          title="Raise Invoice"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/raise-invoice`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <FaFileInvoiceDollar size={12} />
          </StyledIconButton>
        </Tooltip>
        &nbsp;
        <Tooltip
          title="Payment"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/payment-invoice`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdPayments size={12} />
          </StyledIconButton>
        </Tooltip>
      </Box>
    );
  }
  if (parent === 'prospect') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/view`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <AiFillEye size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'master') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip title="View" placement="top" arrow onClick={(e) => navigate(`/${parent}/add`)}>
          <StyledIconButton className="squareIconButton actionButton">
            <FiEye size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  if (parent === 'agreement') {
    return (
      <Tooltip
        title="View & Edit"
        placement="top"
        arrow
        onClick={(e) => navigate(`/${parent}/view`)}
      >
        <StyledIconButton className="squareIconButton actionButton">
          <FiEye size={12} />
        </StyledIconButton>
      </Tooltip>
    );
  }
  if (parent === 'users') {
    return (
      <>
        <Tooltip title="Delete" placement="top" arrow>
          <StyledIconButton className="squareIconButton actionButton">
            <Icon icon={trash2Outline} width={12} />
          </StyledIconButton>
        </Tooltip>
        <Tooltip
          title="Edit"
          placement="top"
          arrow
          onClick={(e) => navigate(`/${parent}/edit/${rowId}`)}
        >
          <StyledIconButton className="squareIconButton actionButton">
            <MdModeEdit size={12} />
          </StyledIconButton>
        </Tooltip>
      </>
    );
  }
  return (
    <>
      <Tooltip title="Delete" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <Icon icon={trash2Outline} width={12} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title="Edit" placement="top" arrow>
        <StyledIconButton className="squareIconButton actionButton">
          <Icon icon={editFill} width={12} />
        </StyledIconButton>
      </Tooltip>
    </>
  );
}
