import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: 4
  }
}));

function UpdateLogIn() {
  const [loanInterested, setLoanInterested] = useState(' ');
  return (
    <Page title="Partner Management System || Log In">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Update Application Status" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <TableContainer
                sx={{ minWidth: 800, overflow: 'auto', marginBottom: '2rem' }}
                aria-label="simple table"
              >
                <Table border={1} borderColor="#ebedf2">
                  <TableHead>
                    <TableRow>
                      <TableCell>Application ID</TableCell>
                      <TableCell align="left">Application Name</TableCell>
                      <TableCell align="left">Bank</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Comments</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">12456789</TableCell>
                      <TableCell align="left">Blanche Turner</TableCell>
                      <TableCell align="left">HDFC</TableCell>
                      <TableCell align="left">
                        <Select
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          value={loanInterested}
                          onChange={(e) => setLoanInterested(e.target.value)}
                          sx={{ minWidth: '300px' }}
                        >
                          <MenuItem value=" " disabled>
                            Select Status
                          </MenuItem>
                          <MenuItem value="U-Doc">U-Doc</MenuItem>
                          <MenuItem value="U-Log in">U-Log in</MenuItem>
                          <MenuItem value="Log in">Log in</MenuItem>
                          <MenuItem value="U-Sanction">U-Sanction</MenuItem>
                          <MenuItem value="Sanction">Sanction</MenuItem>
                          <MenuItem value="U-Disbursement">U-Disbursement</MenuItem>
                          <MenuItem value="Disbursed">Disbursed</MenuItem>
                          <MenuItem value="Query from Bank">Query from Bank</MenuItem>
                          <MenuItem value="Rejected">Rejected</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="left">
                        <TextField
                          fullWidth
                          inputProps={{
                            className: 'textInput'
                          }}
                          placeholder="Write Your Comments"
                          sx={{ minWidth: '300px' }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <Box className="" textAlign="">
              <Button variant="contained" className="mainBtn">
                Update
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default UpdateLogIn;
