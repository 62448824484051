import { useState } from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { CgAssign, CgEnter } from 'react-icons/cg';
import { AiOutlineStop } from 'react-icons/ai';
import { MdNotificationsNone } from 'react-icons/md';
import { HiStatusOnline, HiOutlineStatusOffline } from 'react-icons/hi';
import { FiEdit } from 'react-icons/fi';
import { RiListSettingsLine } from 'react-icons/ri';
import CloseIcon from '@mui/icons-material/Close';
// import export from '@iconify/icons-ic/export';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import { DateRange } from 'react-date-range';
import { navigate } from '@storybook/addon-links';
import { Link, useNavigate } from 'react-router-dom';
import { RootStyle, SearchStyle, DateRangeStyled } from '../Global/Styling';
// ---------------------------------------------------------
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fDate } from '../../utils/formatTime';
// ----------------------------------------------------------------------

export default function InvoiceToolbar({ numSelected, filterName, onFilterName, parent }) {
  const navigate = useNavigate();
  const [isOpenFilter, openFilter] = useState(false);
  const [filterDatesRange, setFilterDatesRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);
  const [openAdd, setOpenAdd] = useState(false);
  const [showTextfield, setShowTextfield] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [assignValue, setAssignValue] = useState(' ');
  console.log(filterDatesRange);
  return (
    <>
      {isOpenFilter && (
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Typography variant="h6" className="formLabel">
                Product
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Product
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3} sx={{ position: 'relative' }}>
              <Typography variant="h6" className="formLabel">
                Dates
              </Typography>
              <Box className="displayDateRange">
                {filterDatesRange[0].startDate !== null
                  ? `${fDate(filterDatesRange[0].startDate)} to `
                  : ''}
                {filterDatesRange[0].endDate !== null ? fDate(filterDatesRange[0].endDate) : ''}
              </Box>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                MenuProps={{
                  className: 'menuOpened'
                }}
                value=" "
                className="datesSelect"
              >
                <DateRangeStyled
                  editableDateInputs={false}
                  onChange={(item) => setFilterDatesRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={filterDatesRange}
                  className="dateRangePicker"
                />
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className="formLabel">
                City
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select City
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className="formLabel">
                Status
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Status
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h6" className="formLabel">
                Source
              </Typography>
              <Select
                fullWidth
                inputProps={{
                  className: 'textInput'
                }}
                value=" "
              >
                <MenuItem value=" " disabled>
                  Select Source
                </MenuItem>
                <MenuItem> Menu 1</MenuItem>
                <MenuItem> Menu 1</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      <RootStyle
        sx={{
          minHeight: 45
        }}
      >
        <Box>
          <Tooltip
            title="Update"
            placement="top"
            arrow
            onClick={(e) => navigate(`/${parent}/update`)}
          >
            <IconButton className="squareIconButton">
              <FiEdit size={16} />
            </IconButton>
          </Tooltip>
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Notification" placement="top" arrow>
            <IconButton className="squareIconButton">
              <MdNotificationsNone size={16} />
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder="Search Invoice"
            classes={{
              root: 'searchHolder',
              input: 'searchInput'
            }}
            startAdornment={
              <InputAdornment position="start">
                <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
          />
          &nbsp;&nbsp;&nbsp;
          <Tooltip title="Filter list">
            <IconButton
              onClick={(e) => {
                if (isOpenFilter) {
                  openFilter(false);
                } else {
                  openFilter(true);
                }
              }}
            >
              <Icon icon={roundFilterList} />
            </IconButton>
          </Tooltip>
        </Box>
      </RootStyle>
      <Dialog open={openAdd} onClose={(e) => setOpenAdd(false)} fullWidth>
        <DialogTitle className="popupTitle">
          <Typography>Select Mode of Application Submission</Typography>
          <IconButton onClick={(e) => setOpenAdd(false)} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={3}
            display="flex"
            justifyContent="space-between"
            textAlign="center"
            mb={2}
          >
            <Grid item xs={4}>
              <Box
                className="appSubmissionLinkB"
                onClick={(e) => {
                  window.open(`http://losdemo.kalolytic.com/`, `_blank`);
                  setOpenAdd(false);
                }}
              >
                <HiStatusOnline className="appSubLinkIcon" />
                <Typography variant="subtitle1">Online</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className="appSubmissionLinkB"
                onClick={(e) => {
                  window.open(`http://offline.kalolytic.com/`, `_blank`);
                  setOpenAdd(false);
                }}
              >
                <HiOutlineStatusOffline className="appSubLinkIcon" />
                <Typography variant="subtitle1">Offline</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                className="appSubmissionLinkB"
                onClick={(e) => {
                  window.open(`http://offline.kalolytic.com/`, `_blank`);
                  setOpenAdd(false);
                }}
              >
                <RiListSettingsLine className="appSubLinkIcon" />
                <Typography variant="subtitle2">Manual</Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={4}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#808080' }}
                onClick={(e) => setShowTextfield(true)}
              >
                Manual
              </Button>
            </Grid> */}
          </Grid>
          {showTextfield === 'true' ? (
            <Box>
              <Box mb={2}>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type master name..."
                />
              </Box>
              <Box>
                <TextField
                  fullWidth
                  inputProps={{
                    className: 'textInput'
                  }}
                  placeholder="Type master name..."
                />
              </Box>
            </Box>
          ) : (
            ''
          )}
        </DialogContent>

        {/* <DialogActions>
          <Button onClick={(e) => setOpenAdd(false)} variant="contained">
            SUBMIT
          </Button>
        </DialogActions> */}
      </Dialog>
      <Dialog open={openAssign} onClose={(e) => setOpenAssign(false)} fullWidth>
        <Box>
          <Box>
            <DialogTitle>27 Prospect Selected</DialogTitle>
          </Box>
        </Box>
        <DialogContent>
          <Select
            fullWidth
            inputProps={{
              className: 'textInput'
            }}
            value={assignValue}
            onChange={(e) => setAssignValue(e.target.value)}
          >
            <MenuItem value=" " disabled>
              Select
            </MenuItem>
            <MenuItem value="sbi">SBI</MenuItem>
            <MenuItem value="axisbank">AXIS BANK</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => setOpenAssign(false)} variant="contained">
            SUBMIT
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
