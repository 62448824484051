import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  tableCellClasses,
  TextareaAutosize
} from '@mui/material';
import { FiEye } from 'react-icons/fi';
import { BiCloudDownload } from 'react-icons/bi';
import { AiFillPrinter, AiFillDelete, AiFillEye } from 'react-icons/ai';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    fontWeight: 'normal',
    padding: '6px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    padding: 4
  }
}));
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  margin: '0 3px',
  [`&:hover`]: {
    color: theme.palette.primary.main
  }
}));

function SubmitLeads() {
  const [loanInterested, setLoanInterested] = useState(' ');
  return (
    <Page title="Partner Management System || Leads">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Submit Application to Bank" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Box className="submitLeadToolbar">
              <Tooltip title="Application View" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <FiEye size={16} />
                </IconButton>
              </Tooltip>
              &nbsp;&nbsp;&nbsp;
              <Tooltip title="Print" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <AiFillPrinter size={16} />
                </IconButton>
              </Tooltip>
              &nbsp;&nbsp;&nbsp;
              <Tooltip title="Download" placement="top" arrow>
                <IconButton className="squareIconButton">
                  <BiCloudDownload size={16} />
                </IconButton>
              </Tooltip>
            </Box>
            <Scrollbar>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <Typography variant="h6" className="formLabel">
                      Bank
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value=" "
                    >
                      <MenuItem value=" " disabled>
                        Select Bank
                      </MenuItem>
                      <MenuItem>HDFC</MenuItem>
                      <MenuItem>ICICI</MenuItem>
                      <MenuItem>IDFC</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box>
                    <Typography variant="h6" className="formLabel">
                      Mode of Submission
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value=" "
                    >
                      <MenuItem value=" " disabled>
                        Select Mode
                      </MenuItem>
                      <MenuItem>Email</MenuItem>
                      <MenuItem>API</MenuItem>
                      <MenuItem>Manual</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Processing Center
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Processing Center"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Bank Contact Person
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type Bank Contact Person"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Comments
                    </Typography>
                    <TextareaAutosize
                      minRows={5}
                      placeholder="Write Your Comments"
                      className="textInput textareaBox"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Scrollbar>
            <Box className="" textAlign="" mb={3}>
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box>
            <TableContainer
              sx={{ minWidth: 800, overflow: 'auto', marginBottom: '2rem' }}
              aria-label="simple table"
            >
              <Table border={1} borderColor="#ebedf2">
                <TableHead>
                  <TableRow>
                    <TableCell>Bank Name</TableCell>
                    <TableCell align="left">Mode of Submission</TableCell>
                    <TableCell align="left">Processing Center</TableCell>
                    <TableCell align="left">Contact Person Name</TableCell>
                    <TableCell align="left">Comments</TableCell>
                    <TableCell align="left">Submited On</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="left">HDFC</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Delhi</TableCell>
                    <TableCell align="left">Hemant</TableCell>
                    <TableCell align="left">Hello</TableCell>
                    <TableCell align="left">27 April, 2022</TableCell>
                    <TableCell align="left">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={12} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={12} />
                        </StyledIconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">HDFC</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Delhi</TableCell>
                    <TableCell align="left">Hemant</TableCell>
                    <TableCell align="left">Hello</TableCell>
                    <TableCell align="left">27 April, 2022</TableCell>
                    <TableCell align="left">
                      <Tooltip title="Delete" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillDelete size={12} />
                        </StyledIconButton>
                      </Tooltip>
                      <Tooltip title="View" placement="top" arrow>
                        <StyledIconButton className="squareIconButton actionButton">
                          <AiFillEye size={12} />
                        </StyledIconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default SubmitLeads;
