import React, { useState } from 'react';
import {
  Step,
  Stepper,
  StepLabel,
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider,
  Tab,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
  DialogTitle,
  FormLabel,
  FormControlLabel,
  Card
} from '@mui/material';
import { Box } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsFillBookmarkCheckFill } from 'react-icons/bs';
import { IoIosAlert } from 'react-icons/io';

// table component
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AttachmentIcon from '@mui/icons-material/Attachment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { ImFolderUpload } from 'react-icons/im';
import { MdOutlinePreview, MdUploadFile, MdUpload, MdOutlineAdd } from 'react-icons/md';
import { BiCloudUpload } from 'react-icons/bi';
import { DiGoogleDrive } from 'react-icons/di';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CgDigitalocean } from 'react-icons/cg';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { FiEdit } from 'react-icons/fi';

// import { CloseIcon } from 'react-icons'
import Switch from '@mui/material/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import pages
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function ViewAgreement(parent) {
  const navigate = useNavigate();
  return (
    <Page title="Partner Management System || Agreement">
      <Container maxWidth="xl">
        <Box pt={3} pb={8}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Edit Agreement" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>
          <Card sx={{ borderRadius: '0' }}>
            <Scrollbar>
              <Box className="submitLeadToolbar">
                <Tooltip
                  title="Edit"
                  placement="top"
                  arrow
                  onClick={(e) => navigate(`/agreement/edit`)}
                >
                  <IconButton className="squareIconButton">
                    <FiEdit size={16} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  marginBottom={2}
                  sx={{ textDecoration: 'underline' }}
                >
                  Bank Details
                </Typography>
                <Grid item container spacing={{ xs: 1, sm: 2, md: 3 }} marginBottom={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" className="reviewLabel">
                      Bank Name
                    </Typography>
                    <Typography variant="subtitle2" className="reviewValue">
                      HDFC
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" className="reviewLabel">
                      Address
                    </Typography>
                    <Typography variant="subtitle2" className="reviewValue">
                      Ramesh Nagar, New Delhi
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" className="reviewLabel">
                      PAN number
                    </Typography>
                    <Typography variant="subtitle2" className="reviewValue">
                      AHVBGF24653S
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" className="reviewLabel">
                      GSTIN
                    </Typography>
                    <Typography variant="subtitle2" className="reviewValue">
                      GST12453687
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" className="reviewLabel">
                      Commission Structure
                    </Typography>
                    <Typography variant="subtitle2" className="reviewValue">
                      Bank
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body1" className="reviewLabel">
                      Turnover Benefit
                    </Typography>
                    <Typography variant="subtitle2" className="reviewValue">
                      Yes
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  variant="subtitle1"
                  marginBottom={2}
                  sx={{ textDecoration: 'underline' }}
                >
                  Branch Details
                </Typography>
                <TableContainer
                  sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
                  aria-label="simple table"
                >
                  <Table border={1} borderColor="#ebedf2">
                    <TableHead>
                      <TableRow>
                        <TableCell>Branch Name</TableCell>
                        <TableCell align="left">Branch Address</TableCell>
                        <TableCell align="left">Contact Person</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">HDFC</TableCell>
                        <TableCell align="left">Ramesh Vihar, New Delhi</TableCell>
                        <TableCell align="left">Ramesh</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  variant="subtitle1"
                  marginBottom={2}
                  sx={{ textDecoration: 'underline' }}
                >
                  Commission Details
                </Typography>
                <TableContainer
                  sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
                  aria-label="simple table"
                >
                  <Table border={1} borderColor="#ebedf2">
                    <TableHead>
                      <TableRow>
                        <TableCell>Loan Type</TableCell>
                        <TableCell align="left">Branch</TableCell>
                        <TableCell align="left">Commission %</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Home loan</TableCell>
                        <TableCell align="left">HDFC Ramesh Vihar</TableCell>
                        <TableCell align="left">5%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  variant="subtitle1"
                  marginBottom={2}
                  sx={{ textDecoration: 'underline' }}
                >
                  Turnover Benefit
                </Typography>
                <TableContainer
                  sx={{ minWidth: 800, overflow: 'auto', marginBottom: '1rem' }}
                  aria-label="simple table"
                >
                  <Table border={1} borderColor="#ebedf2">
                    <TableHead>
                      <TableRow>
                        <TableCell>Loan Type</TableCell>
                        <TableCell align="left">Branch</TableCell>
                        <TableCell align="left">Duration</TableCell>
                        <TableCell align="left">Commission</TableCell>
                        <TableCell align="left">Unit</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Home Loan</TableCell>
                        <TableCell align="left">HDFC Ramesh Vihar</TableCell>
                        <TableCell align="left">10 Yr</TableCell>
                        <TableCell align="left">5%</TableCell>
                        <TableCell align="left">10</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}

export default ViewAgreement;
