import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  InputBase,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

function Login() {
  const navigate = useNavigate();
  const [emailAddr, setEmailAddr] = useState();
  const [password, setPassword] = useState();
  const [open, setOpen] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');
  // let validationMsg = '';

  const handleLogin = () => {
    if (!emailAddr || emailAddr.trim() === '') {
      setValidationMsg('Please enter your E-Mail address');
    } else if (!password || password?.trim() === '') {
      setValidationMsg('Please enter your password');
    } else if (
      (emailAddr?.trim() === 'sandeep.ranjan@kalolytic.com' ||
        emailAddr?.trim() === 'demo@kalolytic.com' ||
        emailAddr?.trim() === 'biswaroop.padhi@lessemi.com') &&
      password?.trim() === 'kredit%01'
    ) {
      navigate('/dashboard');
    } else {
      setValidationMsg('Please enter correct credentials');
    }
    if (validationMsg) {
      handleClickOpen();
    }
    // TODO: 1. then add logic to make HTTP request to login API
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box className="LoginOuter" sx={{ width: '100%', height: '100vh' }}>
      <Container maxWidth="fullWidth" disableGutters="true">
        <Stack>
          <Grid
            container
            className="loginInner"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6} container justifyContent="center" alignContent="center">
              <Grid item xs={11} sm={9} md={7}>
                <Typography variant="h4" className="loginTitle">
                  Partner Management System
                </Typography>
                <Typography variant="body2" className="loginSubTitle">
                  by lessEMI
                </Typography>
                <Typography variant="h5" className="mb-1">
                  Sign In
                </Typography>
                <Typography variant="body2" className="mb-3">
                  Enter your credentials
                </Typography>
                <FormControl fullWidth variant="standard" className="formGroupLogin mb-2">
                  <MailOutlineIcon className="formControlIcon" />
                  <InputBase
                    onChange={(e) => {
                      setEmailAddr(e.target.value);
                    }}
                    label="fullWidth"
                    placeholder="Email Address"
                    inputProps={{
                      'aria-label': 'email address',
                      className: 'formControlFull formControlFullE'
                    }}
                  />
                </FormControl>
                <FormControl fullWidth variant="standard" className="formGroupLogin mb-2">
                  <LockOpenIcon className="formControlIcon" />
                  <InputBase
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    label="fullWidth"
                    type="password"
                    placeholder="Password"
                    inputProps={{
                      'aria-label': 'password',
                      className: 'formControlFull formControlFullP'
                    }}
                  />
                </FormControl>
                <Grid
                  container
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <Grid item>
                    <Checkbox size="medium" />
                    <Typography variant="">Remember Me</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="" className="mr-1">
                      Forgot Password?
                    </Typography>
                    <Link href="#" className="loginLink">
                      Reset
                    </Link>
                  </Grid>
                </Grid>
                <Button className="loginBtn mb-2" variant="button" onClick={handleLogin}>
                  Sign In
                </Button>
                {/* <Box className="orDivider mb-2">or</Box> */}
                <Box className="mb-3 textAlignCenter">
                  Want to Become Partner Please &nbsp;
                  <Link href="#" className="">
                    <strong>Sign Up</strong>
                  </Link>
                  &nbsp;Here
                </Box>
                <Divider className="mb-2" />
                {/* <Grid
                  container
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <Grid item>
                    <Button className="mainBtn" variant="button" onClick={handleLogin}>
                      Login
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button className="mainBtn" variant="button">
                      Sign Up
                    </Button>
                  </Grid>
                </Grid> */}
                <Grid container>
                  <Typography variant="">
                    Copyright © 2020{' '}
                    <a href="https://www.lessemi.com/lessEMI" target="_blank">
                      LessEMI
                    </a>
                    . All rights reserved.
                  </Typography>
                  <Grid item>
                    <Link href="#" className="loginLink mr-2">
                      Privacy Policy
                    </Link>
                    <Link href="#" className="loginLink">
                      Term of Use
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={6}>
              <Box className="loginBanner">
                {/* <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item sm={10} md={8} lg={6}>
                    <Typography variant="h3" marginBottom={3}>
                      Save 1000s hours, Efforts and your own Money
                    </Typography>
                    <Typography variant="body">
                      Kalolytic has many more flexible unique component with very creative design
                      you can save much more with this template.
                    </Typography>
                  </Grid>
                </Grid> */}
              </Box>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Retry</Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default Login;
