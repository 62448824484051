import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function AddEditProspect() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');

  const addMoreMasterValue = () => {
    const prevValue = masterValue;
    const obj = { masterValue: '' };
    prevValue.push(obj);
    setMasterValue(prevValue);
    setRefreshComp(new Date());
  };
  const removeMasterValue = (index) => {
    const prevValue = masterValue;
    const removedItem = prevValue.filter((elm, ind) => ind !== index);
    setMasterValue(removedItem);
    setRefreshComp(new Date());
  };
  return (
    <Page title="Partner Management System || Prospect">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info={rowId ? 'Edit Prospect' : 'Add New Prospect'} />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Card>
            <Scrollbar>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Mobile
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type mobile number"
                      value={mobileNumber}
                      onChange={(event) => setMobileNumber(event.target.value)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type name"
                      value={nameValue}
                      onChange={(event) => setNameValue(event.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      CITY
                    </Typography>
                    <TextField
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type city"
                      value={cityName}
                      onChange={(event) => setCityName(event.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      LOAN TYPE
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value={loanInterested}
                      onChange={(e) => setLoanInterested(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Loan Type
                      </MenuItem>
                      <MenuItem value="homeloan">Home Loan</MenuItem>
                      <MenuItem value="educationloan">Education Loan</MenuItem>
                    </Select>
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      AMOUNT
                    </Typography>
                    <TextField
                      type="number"
                      variant="outlined"
                      inputProps={{
                        className: 'textInput'
                      }}
                      placeholder="Type amount"
                      fullWidth
                      value={amountValue}
                      onChange={(event) => setAmountValue(event.target.value)}
                    />
                  </Box>
                </Grid>

                <Grid item xs={4}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      BANK PREFERENCE
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value={bankPreference}
                      onChange={(e) => setBankPreference(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Bank
                      </MenuItem>
                      <MenuItem value="sbi">SBI</MenuItem>
                      <MenuItem value="axisbank">AXIS BANK</MenuItem>
                    </Select>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className="formRowHolder fullrow">
                    <Typography variant="h6" className="formLabel">
                      SOURCE
                    </Typography>
                    <Select
                      fullWidth
                      inputProps={{
                        className: 'textInput'
                      }}
                      value={sourceValue}
                      onChange={(e) => setSourceValue(e.target.value)}
                    >
                      <MenuItem value=" " disabled>
                        Select Source
                      </MenuItem>
                      <MenuItem value="facebook">Facebook</MenuItem>
                      <MenuItem value="callcentre">Call Centre Application</MenuItem>
                    </Select>
                  </Box>
                </Grid>
              </Grid>
              <Box className="formRowHolder">
                <Button variant="contained">Save</Button>
              </Box>
            </Scrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}
export default AddEditProspect;
