import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Drawer, Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
// import account from '../../_mocks_/account';

import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;
const DRAWER_WIDTH_CLOSE = 50;

const RootStyle = styled('div')(({ theme, isopensidebar }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE
  }
}));

// ----------------------------------------------------------------------

export default function DashboardSidebar({ isopensidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  console.log(isopensidebar);
  useEffect(() => {
    if (isopensidebar === 'true') {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        background: '#eaedf1',
        '& .simplebar-content': {
          background: '#eaedf1',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          px: 2,
          py: 1,
          display: 'flex',
          alignItems: 'center',
          background: 'rgba(145, 158, 171, 0.08)'
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo isopensidebar={isopensidebar} />
        </Box>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle isopensidebar={isopensidebar}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpen}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: isopensidebar === 'true' ? DRAWER_WIDTH : DRAWER_WIDTH_CLOSE,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
