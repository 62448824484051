import { useState } from 'react';
import {
  Card,
  Container,
  Stack,
  Link,
  Typography,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
  Button,
  Grid,
  FormControl,
  InputLabel,
  styled
} from '@mui/material';
import { Icon } from '@iconify/react';
import add from '@iconify/icons-ic/add';
import remove from '@iconify/icons-ic/sharp-minus';
import { Box } from '@mui/system';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { BiCloudUpload, BiCloudDownload } from 'react-icons/bi';
import BrudCrumbs from '../../components/BreadCrumbs';
import Page from '../../components/Page';
import PageTitle from '../../components/PageHeading';
import Scrollbar from '../../components/Scrollbar';

function UploadLeadProspect() {
  const { rowId } = useParams();
  const [masterValue, setMasterValue] = useState([{ masterValue: '' }]);
  const [refreshComp, setRefreshComp] = useState(null);
  const [loanInterested, setLoanInterested] = useState(' ');
  const [amountValue, setAmountValue] = useState('');
  const [bankPreference, setBankPreference] = useState(' ');
  const [sourceValue, setSourceValue] = useState(' ');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [cityName, setCityName] = useState('');

  const [file, setFile] = useState();
  function handleChange(e) {
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const Input = styled('input')({
    display: 'none'
  });
  return (
    <Page title="Partner Management System || Prospect">
      <Container maxWidth="xl">
        <Box pt={3} pb={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <PageTitle info="Upload Leads" />
            <Box>
              <BrudCrumbs />
            </Box>
          </Stack>

          <Scrollbar>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs="10" mb={3}>
                <Box variant="Container" className="uploadBox">
                  <label htmlFor="contained-button-file" onChange={handleChange}>
                    <Input accept="image/*" id="contained-button-file" multiple type="file" />
                    <Box>
                      <BiCloudUpload className="upladIcon" />
                      <Typography variant="h5">Upload</Typography>
                    </Box>
                  </label>
                </Box>
              </Grid>
              <Grid xs="2" item mb={3} sx={{ flexGrow: '1' }}>
                <Button className="mainBtn downloadBtn" variant="contained">
                  <BiCloudDownload className="downloadIcon" />
                  <Typography variant="subtitle1">
                    Download <br />
                    Template
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            {/* <img src={file} alt="upload Preview" className="uploadPreview" /> */}
            {/* <div className="App">
              <img src={file} alt="upload" />
            </div> */}
            <Box className="formRowHolder">
              <Button variant="contained" className="mainBtn">
                Submit
              </Button>
            </Box>
          </Scrollbar>
        </Box>
      </Container>
    </Page>
  );
}
export default UploadLeadProspect;
